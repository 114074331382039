import React, {useEffect, useState} from "react";
import classes from "./SleepState.module.scss";

export const SleepState: React.FC = () => {
    const [state, setState] = useState("");

    useEffect(() => {
        const update = async () => {
            const resp = await fetch("https://amiawake-boi.herokuapp.com/api/sleep");
            const json = await resp.json();

            switch (json.status) {
                case "awake":
                    setState("Barna is awake. 😀");
                    break;
                case "asleep":
                    setState("Barna is sleeping. 🛏️💤");
                    break;
                case "unknown":
                    setState("We don't know or he dead. ☠️");
                    break;
            }
        };

        update();
    }, []);

    return <span className={classes.SleepState}>{state}</span>;
};