import React from 'react';
import {SleepState} from "../../components/SleepState/SleepState";

export function App() {
  return (
    <div className="App">
      <SleepState />
    </div>
  );
}
